@import "../utils/vars";
@import "../utils/mixins";

$filtersWrapperMobileHeight: 55px;

// All screens
app-patients-admission-docs,
app-admissions,
app-patients-docs,
app-timesheets,
app-hospitals,
app-admission-incomplete-reports-page,
app-admission-accuracy-reports-page,
app-patients,
app-education,
app-incidents,
app-patients-incident-report,
app-user-management,
app-documents-history,
app-admin-dashboard,
app-plan-details,
app-test-details,
app-admission-attachments,
app-red-flag-attachments,
app-incidents-attachments,
app-attachments-employee,
app-red-flags,
app-patients-red-flags,
app-medical,
app-attachment-medical,
app-medical-documents-history,
app-mandatory-attachments-report,
app-incident-accuracy-report,
app-employee-daily-report,
app-timesheets-daily-report {
  .tablet-filter {
    display: flex;

    .filters-wrapper {
      display: flex;
      align-items: flex-start;
      margin-top: 3px;
      @media screen and (max-width: 400px) {
        width: 280px;
        min-width: 280px;
      }

      .close-filter {
        display: none;
        font-family: $openSansSemiBold;
        font-size: 14px;
        font-weight: normal;
        color: #4c5560;
        position: relative;
        top: 3px;
        cursor: pointer;

        i {
          display: inline-block;
          transform: scale(-1);
          font-size: 9px;
          position: relative;
          top: -1px;
          margin-right: 5px;
        }
      }
    }

    .apply-box {
      display: none;

      button {
        width: 50%;
        max-width: 128px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 6px;
        margin-right: 6px;
        font-family: $openSansSemiBold;
        font-size: 14px;
        font-weight: normal;
        transition: $transition;
        border-radius: 3px;

        &.cancel {
          color: $violet;

          &:hover {
            color: $violet-hover;
          }
        }

        &.apply {
          color: $white-color;
          background-color: $violet;

          &:hover {
            background-color: $violet-hover;
          }
        }
      }
    }

    .by-date {
      padding-bottom: 0;
      width: 200px;
      @media screen and (max-width: 400px) {
        width: 100%;
      }

      input {
        width: 200px;

        &::placeholder {
          font-size: 13px;
          @media screen and (max-width: 767px) {
            font-size: 14px;
          }
        }
      }

      &.showCalendar {
        &::before {
          border-bottom: 5px solid #9D9EA8;
          border-top: 0;
        }
      }
    }

    &.open {
      @media screen and (max-width: 400px) {
        width: 300px;
        min-width: 300px;
      }
    }

    &__content {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      -ms-overflow-style: none;
      scrollbar-width: thin;
      scrollbar-color: transparent transparent;
      @include scrollbar(transparent, transparent);
      @media screen and (max-width: 767px) {
        padding-bottom: 380px;
      }

      &::-webkit-scrollbar {
        display: none;
        background: transparent;
        width: 0;
      }

      * {
        scrollbar-color: rgba(76, 85, 96, 0.4) rgba(76, 85, 96, 0);
      }
    }
  }

  .filter-count {
    padding-left: 2px;
    color: $green-color;
  }
}

// All screens
app-timesheets,
app-admission-incomplete-reports-page,
app-admission-accuracy-reports-page,
app-incidents,
app-patients-incident-report,
app-admin-dashboard,
app-plan-details,
app-test-details,
app-incidents-attachments,
app-medical,
app-mandatory-attachments-report,
app-incident-accuracy-report,
app-employee-daily-report,
app-timesheets-daily-report {
  .tablet-filter {
    &__overlay {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 54;
      background: rgba(0, 0, 0, 0.3);
      transition: all ease 0.15s;
    }
  }
}

// All screens
app-admissions,
app-patients-docs {
  .tablet-filter {
    &__overlay {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 54;
      background: rgba(0, 0, 0, 0.3);
      transition: $transition;
    }
  }
}

// 2420
app-red-flags {
  .tablet-filter {
    @media screen and (max-width: 2420px) {
      position: fixed;
      flex-wrap: wrap;
      align-items: flex-start;
      z-index: 105;
      right: 0;
      top: 0;
      bottom: 0;
      width: 320px;
      background-color: $white-color;
      transform: translate(100%);
      transition: transform ease 0.2s;
      padding: 0 10px 95px 10px;
      height: 100vh;
      overflow: auto;
    }

    app-multiselect {
      @media screen and (max-width: 2420px) {
        display: block;
        width: 100%;
        margin-right: 0;
      }

      .list {
        &__head {
          @media screen and (max-width: 2420px) {
            max-width: 100%;
            width: 100% !important;
            border: 0 !important;
            padding: 0 29px 0 0 !important;
          }
        }
      }
    }

    &.open {
      @media screen and (max-width: 2420px) {
        transform: translate(0);
      }
    }

    .flatpickr-calendar {
      @media screen and (max-width: 2420px) {
        position: relative !important;
        left: -9px;
      }
    }

    .filters-wrapper {
      @media screen and (max-width: 2420px) {
        width: 100%;
        order: 1;
        justify-content: space-between;
        align-items: center;
        min-height: $filtersWrapperMobileHeight;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }
      @media screen and (max-width: 400px) {
        width: 290px;
        min-width: 290px;
      }

      .close-filter {
        @media screen and (max-width: 2420px) {
          display: block;
        }
      }
    }

    .apply-box {
      @media screen and (max-width: 2420px) {
        display: flex;
        padding: 30px 0 20px 0;
        width: 100%;
        justify-content: center;
        border-top: 1px solid #e8e9f4;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    .statusesList,
    .typesList,
    .hhaStatusesList,
    .retentionSpecialistList,
    .patientStatusesList {
      @media screen and (max-width: 2420px) {
        width: 100%;
        min-width: 300px;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }
    }

    .statusesList {
      @media screen and (max-width: 2420px) {
        order: 2;
      }
    }

    .typesList {
      @media screen and (max-width: 2420px) {
        order: 3;
      }
    }

    .hhaStatusesList {
      @media screen and (max-width: 2420px) {
        order: 4;
      }
    }

    .retentionSpecialistList {
      @media screen and (max-width: 2420px) {
        order: 5;
      }
    }

    .patientStatusesList {
      @media screen and (max-width: 2420px) {
        order: 6;
      }
    }

    .by-date {
      @media screen and (max-width: 2420px) {
        order: 7;
        margin-right: 0;
        border: 0;
        width: 100%;
        min-width: 300px;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }

      &:before {
        @media screen and (max-width: 2420px) {
          content: '';
          position: absolute;
          right: 24px;
          top: 27px;
          display: inline-block;
          border: 5px solid transparent;
          border-top: 5px solid #9D9EA8;
        }
      }

      input {
        @media screen and (max-width: 2420px) {
          padding-left: 0;
          border: none;
          background: transparent;
          width: 100%;
        }

        &::placeholder {
          @media screen and (max-width: 2420px) {
            color: #4C5560;
          }
        }

        &:active,
        &:focus {
          @media screen and (max-width: 2420px) {
            box-shadow: none;
          }
        }
      }

      .icon-calendar {
        @media screen and (max-width: 2420px) {
          display: none;
        }
      }
    }

    &__content {
      @media screen and (max-width: 2420px) {
        overflow-x: hidden;
        max-height: calc(100vh - 95px);
        @include scrollbar(transparent, transparent);
      }
    }
  }

  .search-select {
    @media screen and (max-width: 374px) {
      min-width: auto;
    }
  }
}

// 2250
app-admissions {
  .tablet-filter {
    @media screen and (max-width: 2250px) {
      position: fixed;
      flex-wrap: wrap;
      align-items: flex-start;
      z-index: 105;
      right: 0;
      top: 0;
      bottom: 0;
      width: 320px;
      background-color: $white-color;
      transform: translate(100%);
      transition: transform ease 0.2s;
      padding: 0 10px 95px 10px;
      height: 100vh;
    }

    .statusesList,
    .typeAdmission,
    .salesList {
      @media screen and (max-width: 2250px) {
        padding: 15px 15px 10px 15px !important;
      }
      @media screen and (max-width: 1420px) {
        padding: 12px 15px 12px 15px !important;
      }
      @media screen and (max-width: 400px) {
        width: 100%;
      }
    }

    .statusesList,
    .salesList {
      @media screen and (max-width: 2250px) {
        width: 300px;
        min-width: 300px;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }
      @media screen and (max-width: 400px) {
        width: 100%;
      }

      app-multiselect {
        width: 100%;
      }
    }

    .apply-box {
      @media screen and (max-width: 1200px) {
        display: flex;
        padding: 30px 0 20px 0;
        width: 100%;
        justify-content: center;
        border-top: 1px solid #e8e9f4;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    .list {
      &__head {
        @media screen and (max-width: 2250px) {
          padding-left: 0 !important;
        }
      }
    }

    .flatpickr-calendar {
      @media screen and (max-width: 2250px) {
        position: relative !important;
      }
    }

    .filters-wrapper {
      @media screen and (max-width: 2250px) {
        width: 300px;
        min-width: 300px;
        order: 1;
        justify-content: space-between;
        align-items: center;
        min-height: $filtersWrapperMobileHeight;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }
      @media screen and (max-width: 400px) {
        width: 290px;
        min-width: 290px;
      }

      .close-filter {
        @media screen and (max-width: 2250px) {
          display: block;
        }
      }
    }

    .by-date {
      width: 200px;
      @media screen and (max-width: 2250px) {
        order: 4;
        margin-right: 0;
        border: 0;
        width: 300px;
        min-width: 300px;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }

      &:before {
        @media screen and (max-width: 2250px) {
          content: '';
          position: absolute;
          right: 24px;
          top: 27px;
          display: inline-block;
          border: 5px solid transparent;
          border-top: 5px solid #9D9EA8;
        }
      }

      input {
        @media screen and (max-width: 2250px) {
          padding-left: 0;
          border: none;
          background: transparent;
          width: 100%;
        }

        &::placeholder {
          @media screen and (max-width: 2250px) {
            color: #4C5560;
          }
        }

        &:active,
        &:focus {
          @media screen and (max-width: 2250px) {
            box-shadow: none;
          }
        }
      }

      .icon-calendar {
        @media screen and (max-width: 2250px) {
          display: none;
        }
      }
    }

    .typeAdmission {
      @media screen and (max-width: 2250px) {
        width: 300px;
        min-width: 300px;
        display: block;
        border-bottom: 1px solid #e8e9f4;
        order: 3;
      }

      app-multiselect {
        @media screen and (max-width: 2250px) {
          width: 100%;
        }
      }
    }

    .salesList {
      @media screen and (max-width: 2250px) {
        order: 3;
      }
    }

    .statusesList {
      @media screen and (max-width: 2250px) {
        order: 2;
      }
    }

    &.open {
      @media screen and (max-width: 2250px) {
        transform: translate(0);
      }
    }

    &__content {
      @media screen and (max-width: 2250px) {
        overflow-x: hidden;
        overflow-y: auto;
        overscroll-behavior-y: none;
        max-height: calc(100vh - 95px);
        @include scrollbar(transparent, transparent);
      }
    }
  }
}

// 2100
app-admin-dashboard,
app-patients-red-flags,
app-red-flags {
  .tablet-filter {
    @media screen and (max-width: 2100px) {
      position: fixed;
      flex-wrap: wrap;
      align-items: flex-start;
      z-index: 105;
      right: 0;
      top: 0;
      bottom: 0;
      width: 320px;
      background-color: $white-color;
      transform: translate(100%);
      transition: transform ease 0.2s;
      padding: 0 10px 95px 10px;
      height: 100vh;
      overflow: auto;
    }

    app-multiselect {
      @media screen and (max-width: 2100px) {
        display: block;
        width: 100%;
        margin-right: 0;
      }

      .list {
        &__head {
          @media screen and (max-width: 2100px) {
            max-width: 100%;
            width: 100% !important;
            border: 0 !important;
            padding: 0 29px 0 0 !important;
          }
        }
      }
    }

    &.open {
      @media screen and (max-width: 2100px) {
        transform: translate(0);
      }
    }

    .flatpickr-calendar {
      @media screen and (max-width: 2100px) {
        position: relative !important;
        left: -9px;
      }
    }

    .filters-wrapper {
      @media screen and (max-width: 2100px) {
        width: 100%;
        order: 1;
        justify-content: space-between;
        align-items: center;
        min-height: $filtersWrapperMobileHeight;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }
      @media screen and (max-width: 400px) {
        width: 290px;
        min-width: 290px;
      }

      .close-filter {
        @media screen and (max-width: 2100px) {
          display: block;
        }
      }
    }

    .apply-box {
      @media screen and (max-width: 2100px) {
        display: flex;
        padding: 30px 0 20px 0;
        width: 100%;
        justify-content: center;
        border-top: 1px solid #e8e9f4;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    .statusesList,
    .typesList,
    .hhaStatusesList,
    .retentionSpecialistList,
    .patientStatusesList {
      @media screen and (max-width: 2100px) {
        width: 100%;
        min-width: 300px;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }
    }

    .statusesList {
      @media screen and (max-width: 2100px) {
        order: 2;
      }
    }

    .typesList {
      @media screen and (max-width: 2100px) {
        order: 3;
      }
    }

    .hhaStatusesList {
      @media screen and (max-width: 2100px) {
        order: 4;
      }
    }

    .retentionSpecialistList {
      @media screen and (max-width: 2100px) {
        order: 5;
      }
    }

    .patientStatusesList {
      @media screen and (max-width: 2100px) {
        order: 6;
      }
    }

    .by-date {
      @media screen and (max-width: 2100px) {
        order: 7;
        margin-right: 0;
        border: 0;
        width: 100%;
        min-width: 300px;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }

      &:before {
        @media screen and (max-width: 2100px) {
          content: '';
          position: absolute;
          right: 24px;
          top: 27px;
          display: inline-block;
          border: 5px solid transparent;
          border-top: 5px solid #9D9EA8;
        }
      }

      input {
        @media screen and (max-width: 2100px) {
          padding-left: 0;
          border: none;
          background: transparent;
          width: 100%;
        }

        &::placeholder {
          @media screen and (max-width: 2100px) {
            color: #4C5560;
          }
        }

        &:active,
        &:focus {
          @media screen and (max-width: 2100px) {
            box-shadow: none;
          }
        }
      }

      .icon-calendar {
        @media screen and (max-width: 2100px) {
          display: none;
        }
      }
    }

    &__content {
      @media screen and (max-width: 2100px) {
        overflow-x: hidden;
        max-height: calc(100vh - 95px);
        @include scrollbar(transparent, transparent);
      }
    }
  }

  .search-select {
    @media screen and (max-width: 374px) {
      min-width: auto;
    }
  }
}

app-admin-dashboard {
  .tablet-filter {
    @media screen and (max-width: 2100px) {
      position: fixed;
      flex-wrap: wrap;
      align-items: flex-start;
      z-index: 105;
      right: 0;
      top: 0;
      bottom: 0;
      width: 320px;
      background-color: $white-color;
      transform: translate(100%);
      transition: transform ease 0.2s;
      padding: 0 10px 95px 10px;
      height: 100vh;
    }

    &.open {
      @media screen and (max-width: 2100px) {
        transform: translate(0);
      }
    }

    .filters-wrapper {
      @media screen and (max-width: 2100px) {
        width: 300px;
        min-width: 300px;
        order: 1;
        justify-content: space-between;
        align-items: center;
        min-height: $filtersWrapperMobileHeight;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }

      .close-filter {
        @media screen and (max-width: 1600px) {
          display: block;
        }
      }
    }

    .apply-box {
      @media screen and (max-width: 2100px) {
        display: flex;
        padding: 30px 0 20px 0;
        width: 100%;
        justify-content: center;
        border-top: 1px solid #e8e9f4;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    .statusesList {
      @media screen and (max-width: 2100px) {
        order: 2;
      }
    }

    .flatpickr-calendar {
      @media screen and (max-width: 2100px) {
        position: relative !important;
      }
    }

    .salesList {
      @media screen and (max-width: 2100px) {
        order: 3;
      }
    }

    .statusesList,
    .caregiverList,
    .salesList,
    .positionList {
      @media screen and (max-width: 2100px) {
        width: 300px;
        min-width: 300px;
        display: block;
        padding: 15px 15px 10px 15px;
        border-bottom: 1px solid #e8e9f4;
      }

      app-multiselect {
        @media screen and (max-width: 2100px) {
          width: 100%;
        }
      }
    }

    .incomplete-report {
      @media screen and (max-width: 2100px) {
        order: 2;
        padding: 15px 10px 9px 15px;
        margin-right: 0;
        width: 100%;
        border-bottom: 1px solid #e8e9f4;
      }

      .list {
        &__head {
          width: 100%;
          @media screen and (max-width: 2100px) {
            width: 100% !important;
            border: 0 !important;
            padding-left: 0 !important;
          }
        }
      }
    }

    .caregiverList {
      @media screen and (max-width: 2100px) {
        order: 4;
      }
    }

    .positionList {
      @media screen and (max-width: 2100px) {
        order: 5;
      }
    }

    .by-date {
      @media screen and (max-width: 2100px) {
        order: 5;
        margin-right: 0;
        border: 0;
        width: 300px;
        min-width: 300px;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }

      &:before {
        @media screen and (max-width: 2100px) {
          content: '';
          position: absolute;
          right: 24px;
          top: 27px;
          display: inline-block;
          border: 5px solid transparent;
          border-top: 5px solid #9D9EA8;
        }
      }

      input {
        @media screen and (max-width: 2100px) {
          padding-left: 0;
          border: none;
          background: transparent;
          width: 100%;
        }

        &::placeholder {
          @media screen and (max-width: 2100px) {
            color: #4C5560;
          }
        }

        &:active,
        &:focus {
          @media screen and (max-width: 2100px) {
            box-shadow: none;
          }
        }
      }

      .icon-calendar {
        @media screen and (max-width: 2100px) {
          display: none;
        }
      }
    }

    &__content {
      @media screen and (max-width: 2100px) {
        overflow-x: hidden;
        overflow-y: auto;
        overscroll-behavior: none;
        max-height: calc(100vh - 95px);
        @include scrollbar(transparent, transparent);
      }
    }
  }
}

// 1800
app-patients-admission-docs,
app-patients-incident-report,
app-incidents {
  .tablet-filter {
    @media screen and (max-width: 1800px) {
      position: fixed;
      flex-wrap: wrap;
      align-items: flex-start;
      z-index: 105;
      right: 0;
      top: 0;
      bottom: 0;
      width: 320px;
      background-color: $white-color;
      transform: translate(100%);
      transition: transform ease 0.2s;
      padding: 0 10px 95px 10px;
      height: 100vh;
    }

    .flatpickr-calendar {
      @media screen and (max-width: 1800px) {
        position: relative !important;
      }
    }

    .filters-wrapper {
      @media screen and (max-width: 1800px) {
        width: 300px;
        min-width: 300px;
        order: 1;
        justify-content: space-between;
        align-items: center;
        min-height: $filtersWrapperMobileHeight;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }
      @media screen and (max-width: 400px) {
        width: 290px;
        min-width: 290px;
      }

      .close-filter {
        @media screen and (max-width: 1800px) {
          display: block;
        }
      }
    }

    .by-date {
      width: 200px;
      @media screen and (max-width: 1800px) {
        order: 4;
        margin-right: 0;
        border: 0;
        width: 300px;
        min-width: 300px;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }

      &:before {
        @media screen and (max-width: 1800px) {
          content: '';
          position: absolute;
          right: 24px;
          top: 27px;
          display: inline-block;
          border: 5px solid transparent;
          border-top: 5px solid #9D9EA8;
        }
      }

      input {
        @media screen and (max-width: 1800px) {
          padding-left: 0;
          border: none;
          background: transparent;
          width: 100%;
        }

        &::placeholder {
          @media screen and (max-width: 1800px) {
            color: #4C5560;
          }
        }

        &:active,
        &:focus {
          @media screen and (max-width: 1800px) {
            box-shadow: none;
          }
        }
      }

      .icon-calendar {
        @media screen and (max-width: 1800px) {
          display: none;
        }
      }
    }

    .statusesList,
    .typeAdmission,
    .salesList {
      @media screen and (max-width: 1800px) {
        padding: 15px 15px 10px 15px !important;
      }
      @media screen and (max-width: 1420px) {
        padding: 12px 15px 12px 15px !important;
      }
      @media screen and (max-width: 400px) {
        width: 100%;
      }
    }

    .typeAdmission {
      @media screen and (max-width: 1800px) {
        width: 300px;
        min-width: 300px;
        display: block;
        border-bottom: 1px solid #e8e9f4;
        order: 3;
      }

      app-multiselect {
        @media screen and (max-width: 1800px) {
          width: 100%;
        }
      }
    }

    .salesList {
      @media screen and (max-width: 1800px) {
        order: 3;
      }
    }

    .statusesList {
      @media screen and (max-width: 1800px) {
        order: 2;
      }
    }

    &.open {
      @media screen and (max-width: 1800px) {
        transform: translate(0);
      }
    }
  }
}

// 1800
app-patients-admission-docs {
  .tablet-filter {
    app-multiselect {
      @media screen and (max-width: 1800px) {
        width: 100%;
        border: none;
      }

      .list {
        &__head {
          @media screen and (max-width: 1800px) {
            width: 100%;
            padding-left: 0;
          }
        }
      }
    }

    .statusesList,
    .type,
    .salesList {
      @media screen and (max-width: 1800px) {
        width: 300px;
        min-width: 300px;
        display: block;
        padding: 15px 15px 10px 15px !important;
        border-bottom: 1px solid #e8e9f4;
      }
      @media screen and (max-width: 1420px) {
        padding: 12px 15px 12px 15px !important;
      }
      @media screen and (max-width: 400px) {
        width: 100%;
      }
    }

    .type {
      @media screen and (max-width: 1800px) {
        order: 4;
      }
    }

    .xchangeSync {
      @media screen and (max-width: 1800px) {
        width: 300px;
        min-width: 300px;
        display: block;
        padding: 15px 15px 10px 15px !important;
        border-bottom: 1px solid #e8e9f4;
        order: 4;
      }
      @media screen and (max-width: 1420px) {
        padding: 12px 15px 12px 15px !important;
      }
      @media screen and (max-width: 400px) {
        width: 100%;
      }

      app-multiselect {
        @media screen and (max-width: 1800px) {
          width: 100%;
        }
      }
    }

    .apply-box {
      @media screen and (max-width: 1800px) {
        display: flex;
        padding: 30px 0 20px 0;
        width: 100%;
        justify-content: center;
        border-top: 1px solid #e8e9f4;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    .by-date {
      .flatpickr-innerContainer {
        @media screen and (max-width: 1800px) {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }

    &__content {
      @media screen and (max-width: 1800px) {
        overflow-x: hidden;
        overflow-y: auto;
        overscroll-behavior: none;
        max-height: calc(100vh - 95px);
        @include scrollbar(transparent, transparent);
      }
    }
  }
}

// 1800
app-patients-incident-report,
app-incidents {
  .tablet-filter {
    @media screen and (max-width: 1800px) {
      position: fixed;
      flex-wrap: wrap;
      align-items: flex-start;
      z-index: 105;
      right: 0;
      top: 0;
      bottom: 0;
      width: 320px;
      background-color: $white-color;
      transform: translate(100%);
      transition: transform ease 0.2s;
      padding: 0 10px 95px 10px;
      height: 100vh;
      overflow-x: auto;
    }

    &__content {
      @media screen and (max-width: 1800px) {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: calc(100vh - 95px);
        padding-bottom: 355px;
        @include scrollbar(transparent, transparent);
      }
    }

    .statusesList,
    .type,
    .due-dates {
      @media screen and (max-width: 1800px) {
        width: 300px;
        min-width: 300px;
        display: block;
        padding: 15px 15px 10px 15px;
        border-bottom: 1px solid #e8e9f4;
      }
      @media screen and (max-width: 1420px) {
        padding: 12px 15px 12px 15px !important;
      }
      @media screen and (max-width: 400px) {
        width: 100%;
      }
    }

    .statusesList {
      @media screen and (max-width: 1800px) {
        order: 2;
      }
    }

    .type {
      @media screen and (max-width: 1800px) {
        order: 3;
      }
    }

    .due-dates {
      @media screen and (max-width: 1800px) {
        order: 5;
      }
    }

    .by-date {
      @media screen and (max-width: 1800px) {
        order: 4;
        margin-right: 0;
        border: 0;
        width: 300px;
        min-width: 300px;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }
      @media screen and (max-width: 400px) {
        width: 280px;
        min-width: 280px;
      }

      &:before {
        @media screen and (max-width: 1800px) {
          content: '';
          position: absolute;
          right: 24px;
          top: 27px;
          display: inline-block;
          border: 5px solid transparent;
          border-top: 5px solid #9D9EA8;
        }
        @media screen and (max-width: 400px) {
          right: 5px;
        }
      }

      input {
        @media screen and (max-width: 1800px) {
          padding-left: 0;
          border: none;
          background: transparent;
          width: 100%;
        }

        &::placeholder {
          @media screen and (max-width: 1800px) {
            color: #4C5560;
          }
        }

        &:active,
        &:focus {
          @media screen and (max-width: 1800px) {
            box-shadow: none;
          }
        }
      }

      .icon-calendar {
        @media screen and (max-width: 1800px) {
          display: none;
        }
      }
    }

    .list {
      &__head {
        @media screen and (max-width: 1800px) {
          width: 100%;
          max-width: 100%;
          border: none !important;
          padding-left: 0 !important;
        }
      }
    }
  }
}

// 1600
app-patients-docs,
app-hospitals,
app-patients,
app-user-management,
app-admin-dashboard,
app-admission-attachments,
app-red-flag-attachments,
app-attachments-employee,
app-medical,
app-attachment-medical,
app-mandatory-attachments-report,
app-timesheets,
app-timesheets-daily-report {
  .tablet-filter {
    &.open {
      @media screen and (max-width: 1600px) {
        transform: translate(0);
      }
    }
  }
}

// 1600
app-hospitals,
app-patients,
app-admission-incomplete-reports-page,
app-medical,
app-admin-dashboard,
app-incident-accuracy-report,
app-mandatory-attachments-report,
app-timesheets,
app-timesheets-daily-report {
  .tablet-filter {
    .filters-wrapper {
      @media screen and (max-width: 1600px) {
        width: 300px;
        min-width: 300px;
        order: 1;
        justify-content: space-between;
        align-items: center;
        min-height: $filtersWrapperMobileHeight;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }
      @media screen and (max-width: 400px) {
        width: 290px;
        min-width: 290px;
      }

      .close-filter {
        @media screen and (max-width: 1600px) {
          display: block;
        }
      }
    }
  }
}

// 1600
app-hospitals,
app-patients,
app-admission-incomplete-reports-page,
app-medical,
app-admin-dashboard,
app-incident-accuracy-report,
app-mandatory-attachments-report,
app-timesheets,
app-timesheets-daily-report {
  .tablet-filter {
    @media screen and (max-width: 1600px) {
      position: fixed;
      flex-wrap: wrap;
      align-items: flex-start;
      z-index: 105;
      right: 0;
      top: 0;
      bottom: 0;
      width: 320px;
      background-color: $white-color;
      transform: translate(100%);
      transition: transform ease 0.2s;
      padding: 0 10px 95px 10px;
      height: 100vh;
    }
  }
}

// 1600
app-hospitals,
app-patients,
app-admin-dashboard,
app-mandatory-attachments-report,
app-timesheets,
app-timesheets-daily-report {
  .tablet-filter {
    .apply-box {
      @media screen and (max-width: 1600px) {
        display: flex;
        padding: 30px 0 20px 0;
        width: 100%;
        justify-content: center;
        border-top: 1px solid #e8e9f4;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}

// 1600
app-hospitals,
app-patients,
app-admin-dashboard,
app-medical,
app-mandatory-attachments-report,
app-timesheets,
app-timesheets-daily-report {
  .tablet-filter {
    .statusesList {
      @media screen and (max-width: 1600px) {
        order: 2;
      }
    }
  }
}

// 1600
app-admin-dashboard,
app-admission-attachments,
app-medical,
app-mandatory-attachments-report,
app-timesheets,
app-timesheets-daily-report {
  .tablet-filter {
    .flatpickr-calendar {
      @media screen and (max-width: 1600px) {
        position: relative !important;
      }
    }
  }
}

// 1600
app-patients,
app-admin-dashboard,
app-medical,
app-mandatory-attachments-report,
app-timesheets,
app-timesheets-daily-report {
  .tablet-filter {
    .salesList {
      @media screen and (max-width: 1600px) {
        order: 3;
      }
    }
  }
}

// 1600
app-hospitals {
  .tablet-filter {
    .type {
      @media screen and (max-width: 1600px) {
        order: 3;
      }
    }
  }
}

// 1600
app-admin-dashboard,
app-medical,
app-mandatory-attachments-report {
  .tablet-filter {
    .statusesList,
    .caregiverList,
    .salesList,
    .positionList {
      @media screen and (max-width: 1600px) {
        width: 300px;
        min-width: 300px;
        display: block;
        padding: 15px 15px 10px 15px;
        border-bottom: 1px solid #e8e9f4;
      }
      @media screen and (max-width: 1420px) {
        padding: 12px 15px 12px 15px !important;
      }
      @media screen and (max-width: 400px) {
        width: 100%;
      }

      app-multiselect {
        @media screen and (max-width: 1600px) {
          width: 100%;
        }
      }
    }

    .incomplete-report {
      @media screen and (max-width: 1600px) {
        order: 2;
        padding: 15px 10px 9px 15px;
        margin-right: 0;
        width: 100%;
        border-bottom: 1px solid #e8e9f4;
      }
      @media screen and (max-width: 1420px) {
        padding: 15px 10px 15px 15px;
      }
      @media screen and (max-width: 400px) {
        padding-right: 0;
      }

      .list {
        &__head {
          width: 100%;
          @media screen and (max-width: 1600px) {
            width: 100% !important;
            border: 0 !important;
            padding-left: 0 !important;
          }

          &::before {
            @media screen and (max-width: 400px) {
              right: 0;
            }
          }
        }
      }
    }

    .caregiverList {
      @media screen and (max-width: 1600px) {
        order: 4;
      }
    }

    .positionList {
      @media screen and (max-width: 1600px) {
        order: 5;
      }
    }

    .by-date {
      @media screen and (max-width: 1600px) {
        order: 5;
        margin-right: 0;
        border: 0;
        width: 300px;
        min-width: 300px;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }

      &:before {
        @media screen and (max-width: 1600px) {
          content: '';
          position: absolute;
          right: 24px;
          top: 27px;
          display: inline-block;
          border: 5px solid transparent;
          border-top: 5px solid #9D9EA8;
        }
      }

      input {
        width: 200px;
        @media screen and (max-width: 1600px) {
          padding-left: 0;
          border: none;
          background: transparent;
          width: 100%;
        }

        &::placeholder {
          @media screen and (max-width: 1600px) {
            color: #4C5560;
          }
        }

        &:active,
        &:focus {
          @media screen and (max-width: 1600px) {
            box-shadow: none;
          }
        }
      }

      .icon-calendar {
        @media screen and (max-width: 1600px) {
          display: none;
        }
      }
    }

    &__content {
      @media screen and (max-width: 1600px) {
        overflow-x: hidden;
        overflow-y: auto;
        overscroll-behavior: none;
        max-height: calc(100vh - 95px);
        @include scrollbar(transparent, transparent);
      }
    }
  }
}

// 1600
app-timesheets,
app-timesheets-daily-report {
  .tablet-filter {
    .salesList {
      @media screen and (max-width: 1600px) {
        order: 3;
      }
    }

    .statusesList {
      @media screen and (max-width: 1600px) {
        order: 2;
      }
    }

    .statusesList,
    .salesList {
      @media screen and (max-width: 1600px) {
        width: 300px;
        min-width: 300px;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }
      @media screen and (max-width: 400px) {
        width: 100%;
      }
    }

    .filters-wrapper {
      @media screen and (max-width: 1600px) {
        width: 100%;
        order: 1;
        justify-content: space-between;
        align-items: center;
        min-height: $filtersWrapperMobileHeight;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }
      @media screen and (max-width: 400px) {
        width: 290px;
        min-width: 290px;
      }

      .close-filter {
        @media screen and (max-width: 1600px) {
          display: block;
        }
      }
    }

    .by-date {
      padding-bottom: 20px;
      @media screen and (max-width: 1600px) {
        order: 4;
        margin-right: 0;
        border: 0;
        width: 300px;
        min-width: 300px;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }

      input {
        @media screen and (max-width: 1600px) {
          padding-left: 0;
          border: none;
          background: transparent !important;
          width: 100%;
        }

        &::placeholder {
          @media screen and (max-width: 1600px) {
            color: #4C5560;
          }
        }

        &:active,
        &:focus {
          @media screen and (max-width: 1600px) {
            box-shadow: none;
          }
        }
      }

      &:before {
        @media screen and (max-width: 1600px) {
          content: '';
          position: absolute;
          right: 24px;
          top: 27px;
          display: inline-block;
          border: 5px solid transparent;
          border-top: 5px solid #9D9EA8;
        }
      }

      .icon-calendar {
        @media screen and (max-width: 1600px) {
          display: none;
        }
      }

      .filters-datepicker {
        @media screen and (max-width: 1600px) {
          background: transparent;
          border: none !important;
          display: block;
          padding: 0;
          width: 100%;
          box-sizing: inherit !important;
          font-family: $openSansSemiBold;
        }
      }
    }

    &.open {
      @media screen and (max-width: 1600px) {
        transform: translate(0);
      }
      @media screen and (max-height: 767px) {
        padding: 0 10px 10px 10px;
      }

      .list {
        &__head {
          @media screen and (max-width: 1600px) {
            border: none;
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }

    &__content {
      @media screen and (max-width: 1600px) {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: calc(100vh - 95px);
        @include scrollbar(transparent, transparent);
      }
    }

    app-multiselect {
      @media screen and (max-width: 1600px) {
        width: 100% !important;
      }

      @media screen and (max-width: 767px) {
        min-width: 270px !important;
      }

      .list {
        &__head {
          @media screen and (max-width: 1600px) {
            padding-left: 0 !important;
            border: 0 !important;
            width: 100%;
            max-width: 100%;
          }
          @media screen and (max-width: 1200px) {
            border: none !important;
            width: 100% !important;
          }
        }
      }
    }
  }
}

// 1600
app-admission-incomplete-reports-page,
app-incident-accuracy-report {
  .tablet-filter {
    .by-date {
      z-index: inherit;
      @media screen and (max-width: 1600px) {
        margin-right: 0;
        border: 0;
        width: 100%;
        min-width: 300px;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }

      input {
        @media screen and (max-width: 1600px) {
          padding-left: 0;
          background: transparent;
          width: 100%;
          border: none !important;
        }

        &::placeholder {
          @media screen and (max-width: 1600px) {
            color: #4c5560;
          }
        }

        &:active,
        &:focus {
          @media screen and (max-width: 1600px) {
            box-shadow: none !important;
          }
        }
      }

      &:before {
        @media screen and (max-width: 1600px) {
          content: '';
          position: absolute;
          right: 24px;
          top: 27px;
          display: inline-block;
          border: 5px solid transparent;
          border-top: 5px solid #9D9EA8;
        }
      }

      .icon-calendar {
        @media screen and (max-width: 1600px) {
          display: none;
        }
      }

      .flatpickr-calendar {
        @media screen and (min-width: 1600px) {
          z-index: 9999;
          top: 50px;
          left: 8px;
        }

        &.inline {
          @media screen and (max-width: 1200px) {
            top: initial;
          }
        }
      }

      &.from,
      &.to {
        &::placeholder {
          @media screen and (max-width: 1600px) {
            color: #4c5560;
            opacity: 1;
          }
        }
      }

      &.from {
        @media screen and (max-width: 1600px) {
          order: 3;
        }
      }

      &.to {
        @media screen and (max-width: 1600px) {
          order: 4;
        }
      }
    }

    .incomplete-report {
      @media screen and (max-width: 1600px) {
        min-width: 300px;
      }
    }

    &.open {
      @media screen and (max-width: 1600px) {
        transform: translate(0);
      }
      @media screen and (max-height: 767px) {
        padding: 0 10px 10px 10px;
      }

      .list {
        &__head {
          @media screen and (max-width: 1600px) {
            border: none;
            padding-left: 0;
          }
        }
      }
    }

    &__content {
      @media screen and (max-width: 1600px) {
        flex-direction: column;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        height: auto !important;
        @include scrollbar(transparent, transparent);
      }
    }
  }
}

// 1600
app-patients {
  app-multiselect {
    @media screen and (max-width: 1600px) {
      width: 100%;
    }
  }

  .search-select {
    @media screen and (max-width: 374px) {
      min-width: auto;
    }
  }

  .tablet-filter {
    .statusesList,
    .salesList {
      @media screen and (max-width: 1600px) {
        width: 300px;
        min-width: 300px;
        display: block;
        padding: 15px 15px 10px 15px;
        border-bottom: 1px solid #e8e9f4;
      }
      @media screen and (max-width: 1420px) {
        padding: 12px 15px 12px 15px !important;
      }
      @media screen and (max-width: 400px) {
        width: 280px;
        min-width: 280px;
      }
    }

    .by-date {
      @media screen and (max-width: 1600px) {
        order: 4;
        margin-right: 0;
        border: 0;
        width: 100%;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }

      &:before {
        @media screen and (max-width: 1600px) {
          content: '';
          position: absolute;
          right: 24px;
          top: 27px;
          display: inline-block;
          border: 5px solid transparent;
          border-top: 5px solid #9D9EA8;
        }
      }

      input {
        @media screen and (max-width: 1600px) {
          padding-left: 0;
          border: none;
          background: transparent;
          width: 100%;
        }

        &::placeholder {
          @media screen and (max-width: 1600px) {
            color: #4C5560;
          }
        }

        &:active,
        &:focus {
          @media screen and (max-width: 1600px) {
            box-shadow: none;
          }
        }
      }

      .icon-calendar {
        @media screen and (max-width: 1600px) {
          display: none;
        }
      }
    }
  }
}

// 1600
app-medical-documents-history {
  .tablet-filter {
    .positionList {
      @media screen and (min-width: 1421px) and (max-width: 1600px) {
        order: 0 !important;
      }
    }
  }
}

// 1600
app-admission-incomplete-reports-page,
app-incident-accuracy-report,
app-timesheets-daily-report {
  .tablet-filter {
    .incomplete-report {
      @media screen and (max-width: 1600px) {
        order: 2;
        width: 100%;
        border-bottom: 1px solid #e8e9f4;
        padding: 15px 15px 10px 15px;
      }
      @media screen and (max-width: 1420px) {
        padding: 15px 15px 10px 15px;
      }

      .list {
        &__head {
          @media screen and (max-width: 1600px) {
            padding-left: 0;
          }
        }
      }
    }
  }

  .by-date {
    &.showCalendar {
      .flatpickr-calendar {
        &.inline {
          @media screen and (max-width: 1600px) {
            position: static !important;
          }
        }
      }
    }
  }
}

// 1600
app-patients-docs {
  .tablet-filter {
    @media screen and (max-width: 1600px) {
      position: fixed;
      flex-wrap: wrap;
      align-items: flex-start;
      z-index: 105;
      right: 0;
      top: 0;
      bottom: 0;
      width: 320px;
      background-color: $white-color;
      transform: translate(100%);
      transition: transform ease 0.2s;
      padding: 0 10px 95px 10px;
      height: 100vh;
    }

    .statusesList,
    .type,
    .salesList,
    .xchangeSync,
    .typesList,
    .hhaStatusesList,
    .retentionSpecialistList,
    .patientStatusesList {
      @media screen and (max-width: 1600px) {
        width: 100%;
        min-width: 300px;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }
      @media screen and (max-width: 400px) {
        width: 100%;
      }

      app-multiselect {
        @media screen and (max-width: 1600px) {
          width: 100% !important;
        }

        .list {
          &__head {
            @media screen and (max-width: 1600px) {
              padding-left: 0 !important;
              border: 0 !important;
              width: 100%;
              max-width: 100%;
            }
            @media screen and (max-width: 1200px) {
              border: none !important;
              width: 100% !important;
            }
          }
        }
      }
    }

    .xchangeSync {
      @media screen and (max-width: 1600px) {
        order: 3;
      }
    }

    .typesList {
      @media screen and (max-width: 1600px) {
        order: 3;
      }
    }

    .hhaStatusesList {
      @media screen and (max-width: 1600px) {
        order: 4;
      }
    }

    .retentionSpecialistList {
      @media screen and (max-width: 1600px) {
        order: 5;
      }
    }

    .patientStatusesList {
      @media screen and (max-width: 1600px) {
        order: 6;
      }
    }

    .statusesList {
      @media screen and (max-width: 1600px) {
        order: 2;
      }
    }

    .type {
      @media screen and (max-width: 1600px) {
        order: 4;
      }
    }

    .by-date {
      @media screen and (max-width: 1600px) {
        order: 4;
        margin-right: 0;
        border: 0;
        width: 100%;
        min-width: 300px;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }

      &::before {
        @media screen and (max-width: 1600px) {
          content: '';
          position: absolute;
          right: 24px;
          top: 27px;
          display: inline-block;
          border: 5px solid transparent;
          border-top: 5px solid #9D9EA8;
        }
      }

      input {
        @media screen and (max-width: 1600px) {
          padding-left: 0;
          border: none;
          background: transparent;
          width: 100%;
        }

        &::placeholder {
          @media screen and (max-width: 1600px) {
            color: #4C5560;
          }
        }

        &:active,
        &:focus {
          @media screen and (max-width: 1600px) {
            box-shadow: none;
          }
        }
      }

      .icon-calendar {
        @media screen and (max-width: 1600px) {
          display: none;
        }
      }

      .flatpickr-innerContainer {
        @media screen and (max-width: 1600px) {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }

    .filters-wrapper {
      @media screen and (max-width: 1600px) {
        width: 100%;
        order: 1;
        justify-content: space-between;
        align-items: center;
        min-height: $filtersWrapperMobileHeight;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }
      @media screen and (max-width: 400px) {
        width: 280px;
        min-width: 280px;
      }

      .close-filter {
        @media screen and (max-width: 1600px) {
          display: block;
        }
      }
    }

    .apply-box {
      @media screen and (max-width: 1600px) {
        display: flex;
        padding: 30px 0 20px 0;
        width: 100%;
        justify-content: center;
        border-top: 1px solid #e8e9f4;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    .flatpickr-calendar {
      @media screen and (max-width: 1600px) {
        position: relative !important;
      }
    }

    .salesList {
      @media screen and (max-width: 1600px) {
        order: 3;
      }
    }

    &.open {
      @media screen and (max-width: 1600px) {
        transform: translate(0);
      }
    }
  }
}

// 1420
app-admission-accuracy-reports-page,
app-incident-accuracy-report,
app-documents-history,
app-education,
app-medical-documents-history,
app-employee-daily-report,
app-timesheets-daily-report {
  .tablet-filter {
    @media screen and (max-width: 1420px) {
      position: fixed;
      flex-wrap: wrap;
      align-items: flex-start;
      z-index: 105;
      right: 0;
      top: 0;
      bottom: 0;
      width: 320px;
      background-color: $white-color;
      transform: translate(100%);
      transition: transform ease 0.2s;
      padding: 0 10px 95px 10px;
      height: 100vh;
    }

    .incomplete-report {
      @media screen and (max-width: 1420px) {
        order: 2;
        width: 100%;
        border-bottom: 1px solid #e8e9f4;
        padding: 12px 15px 12px 15px;
      }

      .list {
        &__head {
          @media screen and (max-width: 1420px) {
            padding-left: 0;
          }
        }
      }
    }
  }
}

// 1420
app-documents-history,
app-education,
app-medical-documents-history,
app-employee-daily-report,
app-timesheets-daily-report {
  .tablet-filter {
    .apply-box {
      @media screen and (max-width: 1420px) {
        display: flex;
        padding: 30px 0 20px 0;
        width: 100%;
        justify-content: center;
        border-top: 1px solid #e8e9f4;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    .list {
      &__head {
        @media screen and (max-width: 1420px) {
          border: none !important;
          padding-left: 0 !important;
        }
      }
    }
  }
}

// 1420
app-documents-history,
app-education,
app-medical-documents-history,
app-employee-daily-report,
app-timesheets-daily-report {
  .tablet-filter {
    .filters-wrapper {
      @media screen and (max-width: 1420px) {
        width: 100%;
        order: 1;
        justify-content: space-between;
        align-items: center;
        min-height: $filtersWrapperMobileHeight;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }

      .close-filter {
        @media screen and (max-width: 1420px) {
          display: block;
        }
      }
    }

    &.open {
      @media screen and (max-width: 1420px) {
        transform: translate(0);
      }
    }
  }
}

// 1420
app-admission-incomplete-reports-page,
app-admission-accuracy-reports-page,
app-incident-accuracy-report,
app-employee-daily-report,
app-timesheets-daily-report {
  .tablet-filter {
    &__content {
      @media screen and (max-width: 1420px) {
        flex-direction: column;
        width: 100%;
      }
      @media screen and (max-width: 1200px) {
        overflow-x: hidden;
        overflow-y: auto;
        height: calc(100vh - 95px);
        @include scrollbar(transparent, transparent);
      }
      @media screen and (max-height: 767px) and (max-width: 1420px) {
        height: calc(100vh - 20px);
      }
    }
  }
}

// 1420
app-employee-daily-report,
app-admission-accuracy-reports-page {
  .tablet-filter {
    .by-date {
      @media screen and (max-width: 1420px) {
        min-width: 300px;
      }
    }

    .incomplete-report {
      @media screen and (max-width: 1420px) {
        min-width: 300px;
      }
    }

    &__content {
      @media screen and (max-width: 1420px) {
        height: auto !important;
      }
    }
  }

  .by-date {
    &.showCalendar {
      .flatpickr-calendar {
        &.inline {
          @media screen and (max-width: 1420px) {
            position: static !important;
          }
        }
      }
    }
  }
}

// 1420
app-documents-history,
app-medical-documents-history,
app-employee-daily-report,
app-timesheets-daily-report {
  .tablet-filter {
    @media screen and (max-height: 767px) and (max-width: 767px) {
      overflow-x: scroll;
      padding: 0 10px 10px 10px;
    }
    @media screen and (max-width: 400px) {
      width: 300px;
      min-width: 300px;
    }
    @media screen and (orientation: landscape) and (max-width: 767px) {
      padding-bottom: 200px;
    }

    .statusesList,
    .salesList {
      @media screen and (max-width: 1420px) {
        width: 100%;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }

      ::ng-deep {
        app-multiselect {
          @media screen and (max-width: 1420px) {
            width: 270px;
            margin-right: 0 !important;
          }
          @media screen and (max-width: 400px) {
            width: 100%;
          }
        }
      }
    }

    .statusesList {
      @media screen and (max-width: 1420px) {
        order: 2;
        width: 300px;
        min-width: 300px;
      }
      @media screen and (max-width: 400px) {
        width: 100%;
      }
    }

    .positionList {
      @media screen and (max-width: 1420px) {
        width: 300px;
        min-width: 300px;
        display: block;
        padding: 12px 15px 12px 15px !important;
        border-bottom: 1px solid #e8e9f4;
      }
      @media screen and (max-width: 400px) {
        width: 100%;
      }

      app-multiselect {
        @media screen and (max-width: 1420px) {
          width: 100%;
        }
      }
    }

    .positionList {
      @media screen and (max-width: 1600px) {
        order: 5;
      }
    }

    .xchangeSync {
      @media screen and (max-width: 1420px) {
        width: 300px;
        min-width: 300px;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
        order: 4;
      }
      @media screen and (max-width: 400px) {
        width: 100%;
      }

      app-multiselect {
        @media screen and (max-width: 1420px) {
          width: 270px;
          margin-right: 0 !important;
        }
        @media screen and (max-width: 400px) {
          width: 100%;
        }
      }
    }

    .by-date {
      @media screen and (max-width: 1420px) {
        order: 4;
        margin-right: 0;
        border: 0;
        width: 300px;
        min-width: 300px;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }

      input {
        @media screen and (max-width: 1420px) {
          padding-left: 0;
          border: none;
          background: transparent;
          width: 100%;
        }

        &::placeholder {
          @media screen and (max-width: 1420px) {
            color: #4C5560;
          }
        }

        &:active,
        &:focus {
          @media screen and (max-width: 1420px) {
            box-shadow: none;
          }
        }
      }

      &::before {
        @media screen and (max-width: 1420px) {
          content: '';
          position: absolute;
          right: 24px;
          top: 27px;
          display: inline-block;
          border: 5px solid transparent;
          border-top: 5px solid #9D9EA8;
        }
      }

      .icon-calendar {
        @media screen and (max-width: 1420px) {
          display: none;
        }
      }
    }

    &__content {
      flex-direction: row;
      @media screen and (max-width: 1420px) {
        max-height: calc(100vh - 95px);
        @include scrollbar(transparent, transparent);
      }
    }
  }
}

// 1600
app-timesheets-daily-report {
  .tablet-filter {
    .statusesList {
      @media screen and (max-width: 1600px) {
        order: 2;
      }
    }

    .statusesList,
    .salesList {
      @media screen and (max-width: 1600px) {
        width: 300px;
        min-width: 300px;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }
      @media screen and (max-width: 400px) {
        width: 100%;
      }
    }

    .filters-wrapper {
      @media screen and (max-width: 1600px) {
        width: 100%;
        order: 1;
        justify-content: space-between;
        align-items: center;
        min-height: $filtersWrapperMobileHeight;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }
      @media screen and (max-width: 400px) {
        width: 290px;
        min-width: 290px;
      }

      .close-filter {
        @media screen and (max-width: 1600px) {
          display: block;
        }
      }
    }

    .by-date {
      padding-bottom: 20px;
      @media screen and (max-width: 1600px) {
        order: 4;
        margin-right: 0;
        border: 0;
        width: 300px;
        min-width: 300px;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }

      input {
        @media screen and (max-width: 1600px) {
          padding-left: 0;
          border: none;
          background: transparent !important;
          width: 100%;
        }

        &::placeholder {
          @media screen and (max-width: 1600px) {
            color: #4C5560;
          }
        }

        &:active,
        &:focus {
          @media screen and (max-width: 1600px) {
            box-shadow: none;
          }
        }
      }

      &:before {
        @media screen and (max-width: 1600px) {
          content: '';
          position: absolute;
          right: 24px;
          top: 27px;
          display: inline-block;
          border: 5px solid transparent;
          border-top: 5px solid #9D9EA8;
        }
      }

      .icon-calendar {
        @media screen and (max-width: 1600px) {
          display: none;
        }
      }

      .filters-datepicker {
        @media screen and (max-width: 1600px) {
          background: transparent;
          border: none !important;
          display: block;
          padding: 0;
          width: 100%;
          box-sizing: inherit !important;
          font-family: $openSansSemiBold;
        }
      }
    }

    &.open {
      @media screen and (max-width: 1600px) {
        transform: translate(0);
      }
      @media screen and (max-height: 767px) {
        padding: 0 10px 10px 10px;
      }

      .list {
        &__head {
          @media screen and (max-width: 1600px) {
            border: none;
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }

    &__content {
      @media screen and (max-width: 1600px) {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: calc(100vh - 95px);
        @include scrollbar(transparent, transparent);
      }
    }
  }
}

// 1420
app-hospitals {
  .tablet-filter {
    .statusesList,
    .type,
    .due-dates {
      @media screen and (max-width: 1420px) {
        width: 100%;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }
    }

    .due-dates {
      @media screen and (max-width: 1420px) {
        order: 4;
      }
    }
  }
}

// 1420
app-admission-accuracy-reports-page {
  .tablet-filter {
    .filters-wrapper {
      @media screen and (max-width: 1420px) {
        width: 100%;
        order: 1;
        justify-content: space-between;
        align-items: center;
        min-height: $filtersWrapperMobileHeight;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }

      .close-filter {
        height: 21px;
        @media screen and (max-width: 1420px) {
          display: block;
        }
      }
    }

    .by-date {
      z-index: inherit;
      @media screen and (max-width: 1420px) {
        margin-right: 0;
        border: 0;
        width: 100%;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }

      input {
        @media screen and (max-width: 1420px) {
          padding-left: 0;
          background: transparent;
          width: 100%;
          border: none !important;
        }

        &::placeholder {
          @media screen and (max-width: 1420px) {
            color: #4c5560;
          }
        }

        &:active,
        &:focus {
          @media screen and (max-width: 1420px) {
            box-shadow: none !important;
          }
        }
      }

      &:before {
        @media screen and (max-width: 1420px) {
          content: '';
          position: absolute;
          right: 24px;
          top: 27px;
          display: inline-block;
          border: 5px solid transparent;
          border-top: 5px solid #9D9EA8;
        }
      }

      .icon-calendar {
        @media screen and (max-width: 1420px) {
          display: none;;
        }
      }

      .flatpickr-calendar {
        @media screen and (min-width: 1421px) {
          z-index: 9999;
          top: 50px;
          left: 8px;
        }

        &.inline {
          @media screen and (max-width: 1200px) {
            top: initial !important;
          }
        }
      }

      &.from,
      &.to {
        &::placeholder {
          @media screen and (max-width: 1420px) {
            color: #4c5560;
            opacity: 1;
          }
        }
      }

      &.from {
        @media screen and (max-width: 1420px) {
          order: 3;
        }
      }

      &.to {
        @media screen and (max-width: 1420px) {
          order: 4;
        }
      }
    }

    .accuracy-report {
      @media screen and (max-width: 1420px) {
        order: 2;
        padding: 15px;
        width: 100%;
        border-bottom: 1px solid #e8e9f4;
      }
    }

    &.open {
      @media screen and (max-width: 1420px) {
        transform: translate(0);
      }
      @media screen and (max-height: 767px) {
        padding: 0 10px 10px 10px;
      }

      .list {
        &__head {
          @media screen and (max-width: 1420px) {
            border: none;
          }
        }
      }
    }
  }
}

// 1420
app-education {
  .tablet-filter {
    .categoryList {
      @media screen and (max-width: 1420px) {
        width: 100%;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
        order: 2;
      }
    }

    &__content {
      @media screen and (max-width: 1420px) {
        width: 100%;
      }
      @media screen and (max-width: 1200px) {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: calc(100vh - 95px);
        @include scrollbar(transparent, transparent);
      }
    }
  }
}

app-employee-daily-report {
  .incomplete-report {
    app-multiselect {
      margin-right: 0 !important;
    }
  }

  .date-of {
    @media screen and (max-width: 1420px) {
      order: 4 !important;
    }
  }
}

// 1200
app-user-management,
app-admission-attachments,
app-red-flag-attachments,
app-attachments-employee,
app-attachment-medical,
app-plan-details,
app-test-details,
app-mandatory-attachments-report {
  .tablet-filter {
    @media screen and (max-width: 1200px) {
      position: fixed;
      flex-wrap: wrap;
      align-items: flex-start;
      z-index: 105;
      right: 0;
      top: 0;
      bottom: 0;
      width: 320px;
      background-color: $white-color;
      transform: translate(100%);
      transition: transform ease 0.2s;
      padding: 0 10px 95px 10px;
      height: 100vh;
    }

    .filters-wrapper {
      @media screen and (max-width: 1200px) {
        width: 300px;
        min-width: 300px;
        order: 1;
        justify-content: space-between;
        align-items: center;
        min-height: $filtersWrapperMobileHeight;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }
      @media screen and (max-width: 400px) {
        width: 280px;
        min-width: 280px;
      }

      .close-filter {
        @media screen and (max-width: 1200px) {
          display: block;
        }
      }
    }

    .apply-box {
      @media screen and (max-width: 1200px) {
        display: flex;
        padding: 30px 0 20px 0;
        width: 100%;
        justify-content: center;
        border-top: 1px solid #e8e9f4;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }

      a {
        width: 50%;
        max-width: 128px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 6px;
        margin-right: 6px;
        font-family: $openSansSemiBold;
        font-size: 14px;
        font-weight: normal;
        transition: $transition;
        border-radius: 3px;

        &.cancel {
          color: $violet;

          &:hover {
            color: $violet-hover;
          }
        }

        &.apply {
          color: $white-color;
          background-color: $violet;

          &:hover {
            background-color: $violet-hover;
          }
        }
      }
    }
  }
}

// 1200
app-patients-admission-docs,
app-plan-details,
app-test-details {
  .tablet-filter {
    &__content {
      @media screen and (max-width: 1200px) {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: calc(100vh - 95px);
        padding-bottom: 395px;
        @include scrollbar(transparent, transparent);
      }
    }
  }
}

// 1200
app-user-management,
app-attachments-employee,
app-attachment-medical {
  .tablet-filter {
    .salesList {
      @media screen and (max-width: 1200px) {
        order: 3;
      }
    }

    .by-date {
      @media screen and (max-width: 1200px) {
        order: 4;
        margin-right: 0;
        display: flex;
        border: 0;
        width: 100%;
      }
    }
  }
}

// 1200
app-hospitals,
app-patients {
  .tablet-filter {
    &__content {
      @media screen and (max-width: 1200px) {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: calc(100vh - 95px);
        @include scrollbar(transparent, transparent);
      }
    }
  }
}

// 1200
app-admission-attachments,
app-red-flag-attachments {
  .tablet-filter {
    .statusesList,
    .salesList {
      @media screen and (max-width: 1200px) {
        width: 300px;
        min-width: 300px;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }
      @media screen and (max-width: 400px) {
        width: 100%;
      }
    }

    .statusesList {
      @media screen and (max-width: 1200px) {
        order: 3;
      }
    }

    .salesList {
      @media screen and (max-width: 1200px) {
        order: 4;
      }
    }

    .by-date {
      @media screen and (max-width: 1200px) {
        order: 5;
        margin-right: 0;
        display: flex;
        border: 0;
        width: 300px;
        min-width: 300px;
      }
    }

    &__content {
      @media screen and (max-width: 1200px) {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: calc(100vh - 95px);
        @include scrollbar(transparent, transparent);
      }

      .xchangeSync {
        @media screen and (max-width: 1200px) {
          width: 300px;
          min-width: 300px;
          display: block;
          padding: 15px;
          border-bottom: 1px solid #e8e9f4;
          order: 4;
        }
        @media screen and (max-width: 400px) {
          width: 100%;
        }

        app-multiselect {
          @media screen and (max-width: 1200px) {
            width: 100%;
          }
        }
      }
    }

    .filters-wrapper {
      @media screen and (max-width: 400px) {
        width: 293px;
        min-width: 293px;
      }
    }
  }
}

// 1200
app-plan-details,
app-test-details {
  .tablet-filter {
    .completedList {
      margin-right: 20px;
      @media screen and (max-width: 1200px) {
        width: 100%;
        display: block;
        margin-right: 0;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
        order: 2;
      }

      app-multiselect {
        @media screen and (max-width: 1200px) {
          width: 100%;
        }
      }
    }

    .list {
      &__head {
        @media screen and (max-width: 1200px) {
          padding-left: 0;
          padding-right: 0;
          border: 0;
        }
      }
    }
  }
}

// 1200
app-user-management {
  .tablet-filter {
    align-items: center;
    @media screen and (max-width: 1200px) {
      align-items: start;
    }

    .statusesList,
    .departmentsList,
    .salesList {
      @media screen and (max-width: 1200px) {
        width: 100%;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }
    }

    .statusesList,
    .departmentsList {
      @media screen and (max-width: 1200px) {
        order: 2;
      }
    }

    &__content {
      @media screen and (max-width: 1200px) {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: calc(100vh - 95px);
        width: 100%;
        @include scrollbar(transparent, transparent);
      }
    }
  }
}

// 1200
app-attachments-employee,
app-attachment-medical {
  .tablet-filter {
    .statusesList,
    .salesList {
      @media screen and (max-width: 1200px) {
        width: 100%;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }
    }

    .statusesList {
      @media screen and (max-width: 1200px) {
        order: 2;
      }
    }

    &__content {
      @media screen and (max-width: 1200px) {
        overflow-x: initial;
        overflow-y: initial;
        width: 100%;
        @include scrollbar(transparent, transparent);
      }

      .xchangeSync {
        @media screen and (max-width: 1200px) {
          width: 100%;
          display: block;
          padding: 15px;
          border-bottom: 1px solid #e8e9f4;
          order: 4;
        }

        app-multiselect {
          @media screen and (max-width: 1200px) {
            width: 100%;
          }
        }
      }
    }
  }
}

// 1200
app-incidents-attachments,
app-plan-details,
app-test-details,
app-mandatory-attachments-report {
  app-multiselect {
    .list {
      &__head {
        width: 100% !important;
        border: 1px solid #CFD0DE !important;
        @media screen and (max-width: 1200px) {
          border: none !important;
        }
      }

      .form-group {
        input {
          &:checked {
            + label {
              &:after {
                @media screen and (max-width: 1200px) {
                  left: 6px !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .tablet-filter {
    @media screen and (max-width: 1200px) {
      position: fixed;
      flex-wrap: wrap;
      align-items: flex-start;
      z-index: 105;
      right: 0;
      top: 0;
      bottom: 0;
      width: 320px;
      background-color: $white-color;
      transform: translate(100%);
      transition: transform ease 0.2s;
      padding: 0 10px 95px 10px;
      height: 100vh;
    }

    .xchangeSync {
      @media screen and (max-width: 1200px) {
        width: 100%;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
        order: 4;
        margin-bottom: 100px;
      }
    }

    .incomplete-report {
      @media screen and (max-width: 1200px) {
        order: 2;
        padding: 15px;
        width: 100%;
        border-bottom: 1px solid #e8e9f4;
        margin-right: 0;
      }

      .list {
        &__head {
          @media screen and (max-width: 1200px) {
            width: 100%;
            border: none;
            padding-left: 0;
          }
        }
      }
    }

    .by-date {
      @media screen and (max-width: 1200px) {
        order: 4;
        margin-right: 0;
        border: 0;
        width: 100%;
        display: block;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;

        &:before {
          content: '';
          position: absolute;
          right: 24px;
          top: 27px;
          display: inline-block;
          border: 5px solid transparent;
          border-top: 5px solid #9D9EA8;
        }

        input {
          padding-left: 0;
          border: none;
          background: transparent;
          width: 100%;

          &::placeholder {
            color: #4C5560;
          }

          &:active,
          &:focus {
            box-shadow: none;
          }
        }

        .icon-calendar {
          display: none;
        }
      }
    }

    .filters-wrapper {
      @media screen and (max-width: 1200px) {
        width: 100%;
        order: 1;
        justify-content: space-between;
        align-items: center;
        min-height: $filtersWrapperMobileHeight;
        padding: 15px;
        border-bottom: 1px solid #e8e9f4;
      }

      .close-filter {
        @media screen and (max-width: 1200px) {
          display: block;
        }
      }
    }

    .apply-box {
      @media screen and (max-width: 1200px) {
        display: flex;
        padding: 30px 0 20px 0;
        width: 100%;
        justify-content: center;
        border-top: 1px solid #e8e9f4;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    &__content {
      @media screen and (max-width: 1200px) {
        overflow-x: hidden;
        overflow-y: hidden;
        width: 100%;
        @include scrollbar(transparent, transparent);
      }
    }

    &.open {
      @media screen and (max-width: 1200px) {
        transform: translate(0);
      }
    }
  }
}
